<template>
  <el-col class="datatype-pad-container">
    <template>
      <el-tabs
        v-model="activeTabName"
        stretch
        @tab-click="handledatatypeChange"
      >
        <el-tab-pane
          v-for="tab in datatypeTab"
          :key="tab.type"
          :name="tab.type"
          :label="$t(`general.${tab.type}`)"
        >
          <el-button 
            class="toggle-options-button" 
            type="text" 
            :icon="toggleIcon"
            @click="handleOptionsToggleLayer"
          >
            {{ $t(`Spec.selectDataType`) }}
          </el-button>
          <div
            v-if="isOptionsVisible"
            class="type-options-layer"
          >
            <div class="type-options-section">
              <div class="type-options-title">{{ $t(`field.type`) }}</div>
              <div>
                <el-radio-group 
                  v-model="selectedType"
                  fill="#909399"
                  @change="handleMaintypeChange"
                >
                  <el-radio-button 
                    v-for="(item,index) in maintype"
                    :key="index"
                    :label="item"
                  >
                    {{ $t(`mainType.${item}`) }}
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <el-divider />
            <div class="type-options-section">
              <div class="type-options-title">{{ $t(`field.subtype`) }}</div>
              <div class="type-options-inner">
                <template v-if="noSubtype">
                  <div class="nodata">{{ $t(`Spec.noData`) }}</div>
                </template>
                <el-radio-group 
                  v-model="selectedSubType"
                  fill="#909399"
                  @change="handleSubtypeChange"
                >
                  <el-radio-button 
                    v-for="(item,index) in subtype"
                    :key="index"
                    :label="item"
                  >
                    {{ $t(`subType.${item}`) }}
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <el-button 
              class="type-options-select-button"  
              @click="handleOptionsToggleLayer"
            >
              {{ $t(`general.Select`) }}
            </el-button>
          </div>
          <div v-else>
            <el-row class="selected-datatype-title">
              {{ $t(`general.${selectedDataType}`) }}
              / <span v-if="selectedType">{{ $t(`mainType.${selectedType}`) }}</span>
              <template v-if="selectedSubType">
                / {{ $t(`subType.${selectedSubType}`) }}
              </template>
            </el-row>
            <template>
              <DataList
                :table-data="tableData"
              />
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
    </template>
  </el-col>
</template>

<script>
import { getDatatype } from '@/api/data'
import DataList from './DataList.vue'
import { getDataSpec } from '@/api/specification'

const params = () => ({
  main_type: 'Sport',
  type: '', 
  subtype: ''
})

export default {
  name: 'PadView',
  components: { DataList },
  data () {
    return {
      params: params(),
      activeTabName: 'Sport',
      datatypeTab: [ // for tab,
        {
          type: 'Sport'
        },
        {
          type: 'PhysicalFitness'
        },
        {
          type: 'Physiology'
        }
      ],
      drawer: false,
      selectedDataType: 'Sport',
      selectedType: '',
      selectedSubType: '',
      maintype: [],
      subtype: [],
      tableData: [],
      noSubtype: false,
      isOptionsVisible: false
    }
  },
  computed: {
    toggleIcon() {
      return this.isOptionsVisible ? 'el-icon-caret-bottom' : 'el-icon-caret-right'
    }
  },
  mounted() {
    this.fetchInfo()
    this.loadData()
  },
  methods: {
    loadData () {
      this.getParaStr()
    },
    async fetchInfo() {
      this.allOptions = (await getDatatype()).data
      this.datatype = Object.keys(this.allOptions)
      this.maintypeSport = Object.keys(this.allOptions['Sport'])
      this.maintypePhysicalFitness = Object.keys(this.allOptions['PhysicalFitness'])
      this.maintypePhysiology = Object.keys(this.allOptions['Physiology'])
      this.maintype = Object.keys(this.allOptions[this.selectedDataType])
      this.selectedType = this.maintypeSport[0] // default
      this.subtype = this.maintype[0] // default
      this.params.type = this.selectedType
      this.setSubTypeOptiones()
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const res = await getDataSpec(this.params)
      this.tableData = res.data
      this.listLoading = false
    },
    getParaStr() {
      const strArr = []
      let paraStr = ''
      const obj = this.params
      for (const p in obj) {
        if (obj[p]) {
          strArr.push(p + '=' + obj[p])
        }
      }
      paraStr = strArr.join('&')
      this.paraStr = paraStr
    },
    handleMaintypeChange() {
      this.params.type = this.selectedType
      this.setSubTypeOptiones()
      this.fetchData()
    },
    handleSubtypeChange() {
      this.params.subtype = this.selectedSubType
      this.fetchData()
    },
    handledatatypeChange() {
      this.isOptionsVisible = true
      this.selectedDataType = this.activeTabName
      this.params.main_type = this.activeTabName

      this.maintype = Object.keys(this.allOptions[this.selectedDataType])
      switch (this.activeTabName) {
        case 'Physiology':
          this.params.type = this.maintypePhysiology[0]
          this.selectedType = this.maintypePhysiology[0]
          break
        case 'Sport':
          this.params.type = this.maintypeSport[0]
          this.selectedType = this.maintypeSport[0]
          break
        case 'PhysicalFitness':
          this.params.type = this.maintypePhysicalFitness[0]
          this.selectedType = this.maintypePhysicalFitness[0]
          break
      }
      this.setSubTypeOptiones()
      this.fetchData()
    },
    setSubTypeOptiones() {
      this.subtype = this.allOptions[this.selectedDataType][this.selectedType]
      if (!this.subtype.length) {
        this.noSubtype = true
      } else {
        this.noSubtype = false
      }
      this.params.subtype = this.subtype[0]
      this.selectedSubType = this.subtype[0]
    },
    handleOptionsToggleLayer() {
      this.isOptionsVisible = !this.isOptionsVisible
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.datatype-pad-container{
  .toggle-options-button{
    color: $background_blue !important;
  }
  .type-options-layer{
    margin: 0 auto;
    width: 90vw;
    text-align: center;
    .type-options-section{
      padding: 0.5rem;
      text-align: left;
      .type-options-title{
        padding-bottom: 1rem;
      }
      .type-options-inner{
        min-height: 4rem;
      }
    }
    .type-options-select-button{
      margin: 1rem 0;
      width: 60vw;
      background-color: $background_blue;
      color: $text_light;
    }
  }
  .el-button--text{
    color:$info;
    font-size: 1rem;
  }
  .datatype-toggle-button{
    color: $text_dark;
    position: absolute;
    right: 20px;
    top: -5px;
    z-index: 999;
  }
  .datatype-drawer{
    .datatype-drawer-container{
      color: $text;
      padding: 10px !important;
      .datatype-collapse{
        .nodata{
          padding: 15px 0 0 20px;
        }
        ::v-deep .el-collapse-item__header.is-active{
          color: $datatype_blue;
          font-weight: bold;
        }
        ::v-deep .el-collapse-item__header{
          padding-left: 20px;
        }
        ::v-deep .el-collapse-item__content{
          padding-bottom: 0;
        }
        .collapse-detail{
          padding: 5px 0 5px 0px;
          width: 100%;
        }
        .el-radio-group{
          background-color: #F7F8FA;
          transition:none;
          overflow-y:auto;
        }
        ::v-deep .el-radio-button__inner{
          padding: 12px 0 12px 20px;
          text-align: left;
          background-color: #F7F8FA;
          width:-webkit-fill-available;
          border:none;
          transition:none;
        }

        ::v-deep.el-radio-button:hover, 
        ::v-deep.el-radio-button:focus-within
        {
          .el-radio-button__inner{
            transition:none;
            color: $datatype_blue;
            font-weight: bold;
          }
        } 
      }
    }
  }
  .selected-datatype-title{
    padding: 10px 0;
    color: $deep_blue;
    font-weight: bold;
    font-size: 1rem;
  }
}
</style>
