<template>
  <el-main class="spec-container">
    <!-- main block desk-->
    <el-row class="datatype-desk">
      <!-- 桌機測邊選單 -->
      <el-col
        :md="{span:6}"
        :lg="{span:5}"
        :xl="{span:4}"
      >
        <a
          id="AL"
          href="#AL"
          accesskey="L"
          title="側邊選單[快捷鍵Alt+L]" tabindex="0"
          class="sr-only sr-only-focusable"
        >
          :::
        </a>
        <el-collapse 
          v-model="activeName"
          class="datatype-collapse"
          accordion
          @change="handledatatypeChange"
        >
          <!-- test v-for-collapse -->
          <el-collapse-item 
            v-for="(item,index) in datatypeTab" 
            :key="index"
            :title="$t(`general.${item.type}`)"
            :name="item.type"
          >
            <el-button
              v-for="(mainType,i) in maintype" 
              :key="i"
              :label="mainType"
              type="button"
              class="datatype-button"
              :class="{ activeButton: activeMainType === i }"
              :value="mainType"
              @click="handleMainTypeSelect(i, mainType)"
            >
              <template v-if="mainType">
                {{ $t(`mainType.${mainType}`) }}
              </template>
            </el-button>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <!-- 桌機的子類型選單、目前選擇、表格 -->
      <el-col 
        :md="{span:18}"
        :lg="{span:19}"
        :xl="{span:20}" 
        class="right"
      >
        <MainContain />
        <!-- 子類型選單 -->
        <el-row class="desk-subtype-container">
          <el-col style="font-size:1rem;" :span="2">
            {{ $t(`field.subtype`) }}
          </el-col>
          <el-col :span="22">
            <template v-if="noSubtype">
              <div class="nodata">{{ $t(`Spec.noData`) }}</div>
            </template>
            <el-button
              v-for="(item,i) in subtype" 
              :key="i"
              :label="item"
              type="button"
              class="subtype-button"
              :class="{ activeButton: activeSubType === i }"
              :value="item"
              @click.native="handleSubTypeSelect(item, i)"
            >
              <template v-if="item">{{ $t(`subType.${item}`) }}</template>
            </el-button>
          </el-col>
        </el-row>
        <!-- 運動/ 跑步 / 100 公尺 -->
        <el-row class="selected-datatype-title">
          {{ $t(`general.${selectedDataType}`) }}
          / {{ selectedType ? $t(`mainType.${selectedType}`) : '載入中' }}  
          <template v-if="!noSubtype">
            / {{ $t(`subType.${selectedSubType}`) }}
          </template>
        </el-row>
        <!-- 表格 -->
        <template>
          <DataList
            :table-data="tableData"
          />
        </template>
      </el-col>
    </el-row>
    <!-- main block pad -->
    <el-row class="datatype-pad">
      <PadView />
    </el-row>
  </el-main>
</template>

<script>
// import { mapGetters } from 'vuex'
import { getDatatype } from '@/api/data'
import { getDataSpec } from '@/api/specification'
import DataList from './components/DataList.vue'
import PadView from './components/PadView.vue'
import MainContain from '@/components/Accessibility/MainContain.vue'

const params = () => ({
  main_type: 'Sport', // 資料列別
  type: '', 
  subtype: ''
})

export default {
  name: 'SpecPage',
  components: {
    DataList,
    PadView,
    MainContain
  },
  directives: {
    focus: {
      inserted: function(el) {
        el.focus()
      }
    }
  },
  data () {
    return {
      params: params(),
      activeName: 'Sport',
      activeMainType: 0, // for keyboard focus
      activeSubType: 0, // for keyboard focus
      datatypeTab: [ // for tab, collapse
        {
          type: 'Sport'
        },
        {
          type: 'PhysicalFitness'
        },
        {
          type: 'Physiology'
        }
      ],
      drawer: false,
      allOptions: null,
      selectedDataType: 'Sport',
      selectedType: '',
      selectedSubType: '',
      noSubtype: false,
      tableData: [],
      dialogdatatype: [],
      selectedtype: 'main_type',
      datatype: [],
      maintypePhysiology: [], // Physiology
      maintypeSport: [],
      maintypePhysicalFitness: [],
      maintype: [],
      subtype: [],
      paraStr: ''
    }
  },
  mounted() {
    this.fetchInfo()
    this.loadData()
  },
  methods: {
    loadData () {
      this.getParaStr()
    },
    async fetchInfo() {
      this.allOptions = (await getDatatype()).data
      this.datatype = Object.keys(this.allOptions)
      this.maintypeSport = Object.keys(this.allOptions['Sport'])
      this.maintypePhysicalFitness = Object.keys(this.allOptions['PhysicalFitness'])
      this.maintypePhysiology = Object.keys(this.allOptions['Physiology'])
      this.maintype = Object.keys(this.allOptions[this.selectedDataType])
      this.selectedType = this.maintypeSport[0] // default
      this.params.type = this.selectedType
      this.setSubTypeOptiones()
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const res = await getDataSpec(this.params)
      this.maintype = Object.keys(this.allOptions[this.selectedDataType])
      this.tableData = res.data
      this.listLoading = false
    },
    setSubTypeOptiones() {
      this.subtype = this.allOptions[this.selectedDataType][this.selectedType]
      if (!this.subtype.length) {
        this.noSubtype = true
      } else {
        this.noSubtype = false
      }
      this.params.subtype = this.subtype[0]
      this.selectedSubType = this.subtype[0]
    },
    getParaStr() {
      const strArr = []
      let paraStr = ''
      const obj = this.params
      for (const p in obj) {
        if (obj[p]) {
          strArr.push(p + '=' + obj[p])
        }
      }
      paraStr = strArr.join('&')
      this.paraStr = paraStr
    },
    handleMainTypeSelect(index, mainType) {
      this.activeMainType = index
      this.selectedType = mainType
      this.params.type = this.selectedType
      this.setSubTypeOptiones()
      this.fetchData()
    },

    handleSubTypeSelect(event, i) {
      this.activeSubType = i
      this.selectedSubType = event
      this.params.subtype = this.selectedSubType
      this.fetchData()
    },
    handleSubTypeChange() {
      this.params.subtype = this.selectedSubType
      this.fetchData()
    },
    handledatatypeChange(datatype) {
      if (!datatype) {
        this.params.main_type = this.selectedDataType
      } else {
        this.selectedDataType = datatype
        this.params.main_type = datatype
      }
      this.maintype = Object.keys(this.allOptions[this.selectedDataType])
      switch (datatype) {
        case 'Physiology':
          this.params.type = this.maintypePhysiology[0]
          this.selectedType = this.maintypePhysiology[0]
          break
        case 'Sport':
          this.params.type = this.maintypeSport[0]
          this.selectedType = this.maintypeSport[0]
          break
        case 'PhysicalFitness':
          this.params.type = this.maintypePhysicalFitness[0]
          this.selectedType = this.maintypePhysicalFitness[0]
          break
      }
      this.setSubTypeOptiones()
      this.fetchData()
    },
    toHome() {
      this.$emit('closeSpec')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.spec-container {
  .datatype-desk{
      display: block;
    }
    .datatype-pad{
      display: none;
    }
  
  .right{
    color:$text;
    padding: 1.25rem;
    text-align: left;
  }
  .datatype-collapse{
    .nodata{
      color: #ffffff;
      padding: 1rem 0 0 1.25rem;
      background-color: $datatype_blue;
    }
    ::v-deep .el-collapse-item__header.is-active{
      background-color: $background_dark;
    }
    ::v-deep .el-collapse-item__header:focus{
      background-color: $background_dark;
    }
    ::v-deep .el-collapse-item__header{
      background-color: $background_blue;
      color: #ffffff;
      padding-left: 1.25rem;
    }
    ::v-deep .el-collapse-item__content{
      background-color: $datatype_blue;
      padding-bottom: 0;
    }
    .collapse-detail{
      width: 100%;
    }
    .datatype-button{
      width: 100%;
      text-align: left;
      margin-left: 0;
      background-color: #f3f5f6;
    }
    .datatype-button:hover,
    .datatype-button:focus,
    .activeButton{ // for maintype
      background-color: $datatype_blue;
      color: $text_light;
    }
    .datatype-button:hover{
      font-weight: bold;
    }
  }
  .activeButton{ // for subtype
    background-color: $datatype_blue;
    color: $text_light;
  }
  .desk-subtype-container{
    border-bottom: 1px solid $deep_blue;
    padding-bottom: 1.25rem;
    .subtype-button{
      margin-bottom: 0.3125rem;
      padding: 0.3125rem 0.625rem;
    }
    .subtype-button:hover,
    .subtype-button:focus
    {
      background-color: $datatype_blue;
      color: $text_light;
    }
  }
  .selected-datatype-title{
    padding: 1.875rem 0 0.625rem 0;
    color: $deep_blue;
    font-weight: bold;
  }

  @media screen and (max-width:992px){
    .datatype-desk{
      display: none;
    }
    .datatype-pad{
      display: block;
      padding: 0.625rem 1.25rem;
    }
  }
}
</style>
