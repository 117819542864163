<template>
  <div>
    <div class="desk-view">
      <table class="spec-table spec-table">
        <caption class="sr-only">此表列出每一種數據類型的量測項目，並提供各種量測項目的單位、是否為必填以及填寫範例等資訊。</caption>
        <tr>
          <th scope="col">{{ $t(`Spec.item`) }}</th>
          <th scope="col">{{ $t(`Spec.unit`) }}</th>
          <th scope="col">{{ $t(`Spec.required`) }}</th>
          <th scope="col">{{ $t(`Spec.reasonalbe_data`) }}</th>
          <th scope="col">{{ $t(`Spec.Example`) }}</th>
        </tr>
        <tr v-for="(data, index) in tableData" :key="index">
          <td>{{ $t(`field.${data.name}`) }}</td>
          <td>
            <template v-if="data.unit">
              {{ $t(`Unit.${data.unit}`) }}
            </template>
          </td>
          <td>
            <span
              :class="data.required == 'true'?'text-danger':'text-info'"
            >
              {{ $t(`Spec.${data.required}`) }}
            </span>
          </td>
          <td>{{ data.reasonalbe_data }}</td>
          <td class="min-td"> {{ data.example }}</td>
        </tr>
      </table>
    </div>
    <div class="pad-phone-view">
      <el-row
        v-for="(data, index) in tableData" :key="index"   
        class="measure-info-card"
      >
        <el-col class="main-col">
          <div class="top-line">
            {{ $t(`field.${data.name}`) }}
          </div>
          <el-row type="flex">
            <el-col class="example-col">
              <div class="measure-sub-title"> {{ $t(`Spec.Example`) }} </div>
              <div> {{ data.example }} </div>
            </el-col>
            <el-col :class="['reasonable-col',langStyle]">
              <div class="measure-sub-title"> {{ $t(`Spec.reasonalbe_data`) }} </div>
              <div> {{ data.reasonalbe_data }} </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col class="required-col">
          <template v-if="data.required ==='false'">
            {{ $t(`Spec.nonRequired`) }}
          </template>
          <template v-else>
            <span class="required">
              {{ $t(`Spec.required`) }}
            </span>
          </template>
        </el-col>
      </el-row>
    </div>
    <template v-if="!tableData.length">
      <el-empty :description="$t('general.NoData')" />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DataList',
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['language']),
    langStyle () {
      return this.language === 'zh-TW' ? '' : 'lang-style'
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
@media (min-width: 993px){
  .desk-view{
    display: block;
  }
  .pad-phone-view{
    display: none;
  }
}
@media (max-width: 992px){
  .desk-view{
    display: none;
  }
  .pad-phone-view{
    display: block;
  }
}
.spec-table{
  width: 100% !important;
  border: 1px solid #ebeef5;
  th, td{
    font-size: 0.825rem;
    line-height: 3;
    padding-left: 0.825rem;
  }
  th{
    background:#2d3a4b;
    color:#faf9f8;
  }
  td{
    border-bottom: 1px solid #ebeef5;
  }
  .min-td{
    min-width:10%;
  }
  .text-info{
    color: $text;
  }
  .text-danger{
    color: $danger;
  }
}
.pad-phone-view{
  font-size: 0.875rem;
  .measure-info-card{
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 0.8rem;
    display: flex;
    .main-col{
      min-width: 80%;
    }
    .required-col{
      text-align: right;
      .required{
        color: $danger;
      }
    }
    .reasonable-col{
      min-width: 80%;
    }
    .reasonable-col.lang-style{
      min-width: 70%;
    }
    .top-line{
      padding-bottom: 0.8rem;
    }
    .measure-sub-title{
      color: $datatype_blue;
      font-weight: 500;
      padding-bottom: 0.25rem;
    }
  }
}
</style>
